import React from "react";
import { DashboardModule } from "@app/dashboard/controls/DashboardModule";
import { createSearchParams, Link } from "react-router-dom";
import { useWorkspaces } from "@app/workspace/contexts/useWorkspaces";
import AnimatePing from "@app/common/AnimatedPing";
import { useDataActor } from "@app/data/useDataActor";
import { base64Encode, findInList } from "@app/common/utils";
import { useAttributesDataActor } from "@app/data/config/hooks/useAttributesDataActor";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useAlerts } from "@app/contexts/useAlerts";
import { useFeature } from "flagged";
import { useAlertStatus } from "@app/contexts/useAlerts/useAlertStatus";

/**
 * These values correspond to the values that are applied to the stageStatusFormula attribute
 * @type {string[]}
 */
const stageStatusValues = [
    "Order Entry",
    "Vendor Confirmation",
    "Style and PO Creation",
    "Buyer Enrichment",
    "Sample Management",
    "Product Photography",
    "Post Photo Production",
    "Copywriting",
    "Publish",
    "Final Product Content",
    "Published",
    "Unpublished"
];

/**
 * These have not been configured on the formula.
 * When they're configured, they can be moved to the stageStatusValues array
 * @type {string[]}
 */
const notConfiguredStageStatusValues = [];

/**
 * Creates a JSON Logic query for looking for a given stage status value on the stageStatusFormula attribute
 * @param stageStatusValue
 * @returns {{logic: {and: [{"==": [{var: "stageStatusFormula"},stageStatusValue]}]}}}
 */
const createStageStatusQuery = (stageStatusValue) => {
    return {
        logic: {
            and: [{
                "==": [
                    { "var": "stageStatusFormula" },
                    stageStatusValue
                ]
            }]
        }
    };
};

/**
 * o
 * @type {{query: {logic: {and: {"==": [{var: "stageStatusFormula"},stageStatusValue]}[]}}, queryParam: URLSearchParams, stageStatusValue: *}[]}
 */
const stageStatusQueries = stageStatusValues.map((stageStatusValue) => {
    const query = createStageStatusQuery(stageStatusValue);
    const encodedQuery = base64Encode(JSON.stringify(query));
    const queryParam = createSearchParams({ gridAdvancedFilters: encodedQuery });
    return {
        stageStatusValue,
        query,
        queryParam
    };
});

const StageStatusLink = ({ projectionAttributeList, stageStatusQueryObj, productWorkspaceObj }) => {
    const { dataActorState, dataActorData: primaryEntityData } = useDataActor({
        dataActorOptions: {
            dataActorType: "entity",
            entity: productWorkspaceObj?.primaryEntity,
            type: `stageStatus_${stageStatusQueryObj?.stageStatusValue}`,
            projectionAttributeList,
            dataActorSavedQueryObject: stageStatusQueryObj?.query?.logic,
            receiveEvaluatedBobj: true,
            loadOnSpawn: true,
        },
        spawnSource: "DashboardModuleStageStatus"
    });
    const to = `${productWorkspaceObj?.specifications?.path}?${stageStatusQueryObj?.queryParam}&stageStatusNavigation=true`;
    const isLoading = !dataActorState?.matches("loader.idle");
    const isFailed = !!(!dataActorState?.event?.data || dataActorState?.event?.data?.code === "ERR_NETWORK");

    return (
        <Link key={to} to={to} className="flex items-center justify-between px-4 py-6  border-b hover:bg-teal-50 hover:bg-opacity-20 hover:text-teal-900 text-black text-2xl">
            <span>{stageStatusQueryObj?.stageStatusValue}</span>
            {isLoading ? <AnimatePing/> : <>{ isFailed ? <span className="font-bold text-3xl"><ExclamationCircleOutlined style={{ fontSize: '22px' }} /></span> : <span className="font-bold text-3xl">{primaryEntityData.length}</span>}</>}
        </Link>
    );
};

const LegacyDashboardModuleStageStatus = ({}) => {
    const { attributesDataActorData: attributeList } = useAttributesDataActor();
    const { findWorkspaceObjBasedOnId } = useWorkspaces();
    const productWorkspaceObj = findWorkspaceObjBasedOnId("product");
    const stageStatusFormulaAttributeObj = findInList(attributeList, { entity: productWorkspaceObj?.primaryEntity, name: "stageStatusFormula" });
    const projectionAttributeList = [...(stageStatusFormulaAttributeObj?.specifications?.rendererParams?.formulaProjectionAttributeList ?? []), stageStatusFormulaAttributeObj?.schemaId];

    return (
        <DashboardModule className="col-start-8 col-end-13 row-span-3">
            <DashboardModule.Header>
                <i className="icon-stage-status-clipboard"/>
                <DashboardModule.Title>
                    Stage Status
                </DashboardModule.Title>
            </DashboardModule.Header>
            <DashboardModule.Body className="px-0 py-0">
                {stageStatusQueries?.map((stageStatusQueryObj) => {
                    return (
                        <StageStatusLink key={stageStatusQueryObj?.queryParam} projectionAttributeList={projectionAttributeList} stageStatusQueryObj={stageStatusQueryObj} productWorkspaceObj={productWorkspaceObj}/>
                    );
                })}
                {
                    notConfiguredStageStatusValues.map((notConfiguredStageStatusValue) => (
                        <div key={notConfiguredStageStatusValue} className="flex items-center justify-between px-4 py-6  border-b text-black text-2xl opacity-40 cursor-default">
                            <span>{notConfiguredStageStatusValue}</span>
                            <span>-</span>
                        </div>
                    ))
                }
            </DashboardModule.Body>
        </DashboardModule>
    );
};


const SavedQueryStageStatusLink = ({ stageStatus }) => {
    const { isLoading, savedQueryCount: count, isFailed } = useAlertStatus({ alert: stageStatus });

    return (
        <Link key={stageStatus.alertId} to={stageStatus.path} className="flex items-center justify-between px-4 py-6  border-b hover:bg-teal-50 hover:bg-opacity-20 hover:text-teal-900 text-black text-2xl">
            <span>{stageStatus?.displayName}</span>
            {isLoading ? <AnimatePing/> : <>{ isFailed ? <span className="font-bold text-3xl"><ExclamationCircleOutlined style={{ fontSize: '22px' }} /></span> : <span className="font-bold text-3xl">{count}</span>}</>}
        </Link>
    );
};

const DashboardModuleSavedQueryStageStatus = () => {
    const { stageStatusList } = useAlerts();
    return (
        <DashboardModule className="col-start-8 col-end-13 row-span-3">
            <DashboardModule.Header>
                <i className="icon-stage-status-clipboard"/>
                <DashboardModule.Title>
                    Stage Status
                </DashboardModule.Title>
            </DashboardModule.Header>
            <DashboardModule.Body className="px-0 py-0">
                {stageStatusList.map((stageStatus) => {
                    return (
                        <SavedQueryStageStatusLink key={stageStatus.alertId} stageStatus={stageStatus} />
                    );
                })}
            </DashboardModule.Body>
        </DashboardModule>
    );
};

export const DashboardModuleStageStatus = () => {
    const shouldUseSavedQueryAlertsModule = useFeature('readGraphData') || useFeature('savedQueryAlerts');
    if (shouldUseSavedQueryAlertsModule) {
        return <DashboardModuleSavedQueryStageStatus />;
    }

    return <LegacyDashboardModuleStageStatus />;
};

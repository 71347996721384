// @ts-check
import request from './request';
import { baseConfig } from '@app/config/baseConfig';
// @ts-ignore
import mockSavedQueryResults from './mocks/mockSavedQueryResults.json';
import { base64Encode } from "@app/common/utils";
import { SweftError } from "@app/common/SweftError";
import { AxiosError } from 'axios';
import { flagNames, isFlagOn } from "@app/common/featureFlags";

const api = `${baseConfig.GATEWAY_API_BASE_PROTOCOL}//api.${baseConfig.GATEWAY_API_BASE}`;

const dataMocking = true;

export const VanguardService = {
    /**
     * Check status of the service
     * @returns {Promise<{}>}
     */
    status: async () => {
        return request({
            method: "GET",
            url: `${api}/data/health/status`
        });
    },
    apiUrl: `${api}/data`,
    schema: {
        getEntities: async () => {
            const response = await request({
                method: "GET",
                url: `${api}/schema/entities`
            });
            return response.data;
        },
        createEntity: async ({ entity, ...entityProperties }) => {
            if (typeof entity !== "string") {
                console.error("Sweft Error 1955: Invalid entity passed to schema.createEntity of VanguardService", entity);
                throw new Error("Sweft Error 1955: Invalid entity passed to schema.createEntity of VanguardService");
            }
            const response = await request({
                method: "PUT",
                url: `${api}/commands/schema/${entity}`,
                data: {
                    ...entityProperties
                },
                withCredentials: true
            });
            return response.data;
        },
        /**
         * @returns {Promise<{ response: Array<SweftAttributeSchema>}>}
         */
        getAttributes: async () => {
            const response = await request({
                method: "GET",
                url: `${api}/schema/attributes`
            });
            return response.data;
        },
        createAttribute: async ({ entity, ...attribute }) => {
            if (typeof entity !== "string") {
                console.error("Sweft Error 1966: Invalid entity passed to schema.createAttribute of VanguardService", entity);
                throw new Error("Sweft Error 1966: Invalid entity passed to schema.createAttribute of VanguardService");
            }
            const response = await request({
                method: "PUT",
                url: `${api}/commands/schema/${entity}/${attribute.name}`,
                data: {
                    entity,
                    ...attribute
                },
                withCredentials: true
            });
            return response.data;
        },
        updateAttribute: async ({ attribute, fieldBeingUpdated }) => {
            if (typeof attribute?.entity !== "string" || typeof attribute?.name !== "string") {
                console.error("Sweft Error 1977: Invalid attribute passed to schema.updateAttribute of VanguardService", attribute);
                throw new Error("Sweft Error 1977: Invalid attribute passed to schema.updateAttribute of VanguardService");
            }
            const { transientStamp } = attribute;
            const response = await request({
                method: "PATCH",
                url: `${api}/commands/schema/${attribute.entity}/${attribute.name}`,
                data: {
                    ...fieldBeingUpdated,
                    transientStamp
                },
                withCredentials: true
            });
            return response.data;
        },
    },
    data: {
        /**
         * Load business objects of the provided entity
         * @prop {string} entity
         * @returns {Promise<*|*[]>}
         */
        getBusinessObjectsOfEntity: async ({
            entity,
            schemaTreeExclusionList,
            relatedProjectionPaths,
            additionalJsonLogicQueryObject,
            projectionAttributeList,
            receiveEvaluatedBobj,
            formulaForBackendEval,
            preFilterEntityMap,
            savedQueryId,
            additionalSavedQueryIdList
        }) => {
            if (typeof entity !== "string") {
                console.error("Sweft Error 1992: Invalid entity passed to getBusinessObjectsOfEntity of VanguardService", entity);
                throw new Error("Sweft Error 1992: Invalid entity passed to getBusinessObjectsOfEntity of VanguardService");
            }

            const params = new URLSearchParams();
            if (schemaTreeExclusionList) {
                params.set("schemaTreeExclusionList", schemaTreeExclusionList);
            }

            if (additionalJsonLogicQueryObject) {
                params.set("jlQuery", base64Encode(JSON.stringify(additionalJsonLogicQueryObject)));
            }

            if (relatedProjectionPaths) {
                params.set("relatedProjectionPaths", relatedProjectionPaths);
            }

            if (projectionAttributeList) {
                params.set("projectionAttributeList", Array.from(new Set(projectionAttributeList)).join(","));
            }

            if (receiveEvaluatedBobj) {
                params.set("evalBobjs", true);
            }

            if (formulaForBackendEval) {
                params.set('formulaForBackendEval', formulaForBackendEval);
            }

            if (preFilterEntityMap) {
                params.set('preFilter', base64Encode(JSON.stringify(preFilterEntityMap)));
            }

            if (savedQueryId) {
                params.set("savedQueryId", savedQueryId);
            }

            if (additionalSavedQueryIdList) {
                params.set("additionalSavedQueryIdList", additionalSavedQueryIdList.join(","));
            }

            const url = `${api}/data/${entity}${params.toString() ? `?${params.toString()}` : ''}`;

            if (url.length > 9000) {
                console.error("Too many values in the query string. Please reduce the number of values in the query string");
                throw new AxiosError("Too many values in the query string. Please reduce the number of values in the query string", 'ERR_PREFILTER' );
            }

            let path = "data";
            if (isFlagOn(flagNames.readGraphData)) {
                path = "graph-data";
            }

            const response = await request({
                method: "GET",
                url: `${api}/${path}/${entity}${params.toString() ? `?${params.toString()}` : ''}`,
            });

            if (response?.data) {
                if (response.data.redirect) {
                    const redirectResponse = await fetch(response.data.redirect);
                    return await redirectResponse.json();
                }
                if (!Array.isArray(response?.data)) {
                    new SweftError(`Error loading data for entity: ${entity}`);
                    return { responseType: "error", response };
                }
                return response?.data;
            }
        },
        getBusinessObjectsOfEntitySavedQuery: async ({ entity, savedQuery, additionalJsonLogicQueryObject }) => {
            if (typeof entity !== "string") {
                console.error("Sweft Error 1993: Invalid entity passed to data.getBusinessObjectsOfEntitySavedQuery of VanguardService", entity);
                throw new Error("Sweft Error 1993: Invalid entity passed to data.getBusinessObjectsOfEntitySavedQuery of VanguardService");
            }
            const params = new URLSearchParams();
            params.set('savedQueryId', savedQuery.id);
            if (additionalJsonLogicQueryObject) {
                params.set("jlQuery", base64Encode(JSON.stringify(additionalJsonLogicQueryObject)));
            }
            const response = await request({
                method: "GET",
                url: `${api}/data/${entity}?${params.toString()}`,
            });
            return response?.data || [];
        },
        getSingleBusinessObject: async ({ entity, bObjId }) => {
            if (typeof entity?.entity !== "string" || typeof bObjId !== "string") {
                console.error("Sweft Error 1993: Invalid entity passed to data.getSingleBusinessObject of VanguardService", entity);
                throw new Error("Sweft Error 1993: Invalid entity passed to data.getSingleBusinessObject of VanguardService");
            }
            const response = await request({
                method: "GET",
                url: `${api}/data/${entity.entity}/${bObjId}`,
            });
            return response?.data || {};
        },
        createBusinessObject: async ({ businessObject }) => {
            if (!businessObject?.id || !businessObject?.entity || businessObject?.id === 'undefined' || businessObject?.entity === 'undefined') {
                throw new Error("Sweft Error 1982: Unable to create business object as either id or entity is not set");
            }
            const response = await request({
                method: "PUT",
                url: `${api}/commands/object/${businessObject.entity}/${businessObject.id}`,
                data: {
                    ...businessObject
                },
                withCredentials: true
            });
            return response.data;
        },
        createBusinessObjectInBatch: async ({ businessObjectList, entity }) => {
            if (!entity) {
                throw new Error("Sweft Error 1982: Unable to create business object as entity is not set");
            }
            const response = await request({
                method: "PUT",
                url: `${api}/commands/object/batch/${entity}`,
                data: {
                    businessObjectList
                },
                withCredentials: true
            });
            return response.data;
        },
        updateBusinessObject: async ({ businessObject, fieldBeingUpdated, keyProperty }) => {
            const id = businessObject[keyProperty];
            const { transientStamp, entity } = businessObject;
            if (!id || !entity || id === 'undefined' || entity === 'undefined') {
                console.error("Sweft Error 1984: Unable to update this business object as either id or entity is not set", businessObject);
                throw new Error("Sweft Error 1984: Unable to update this business object as either id or entity is not set");
            }
            const response = await request({
                method: "PATCH",
                url: `${api}/commands/object/${entity}/${id}`,
                data: {
                    ...fieldBeingUpdated,
                    transientStamp
                },
                withCredentials: true
            });
            return response.data;
        },
        updateBusinessObjectInBatch: async ({ businessObjectList, entity, keyProperty }) => {
            if (!entity) {
                throw new Error("Sweft Error 1982: Unable to update business object as entity is not set");
            }
            const response = await request({
                method: "PATCH",
                url: `${api}/commands/object/batch/${entity}`,
                data: {
                    businessObjectList,
                    keyProperty
                },
                withCredentials: true
            });
            return response.data;
        },
        entityAllSavedQueries: async ({ entity, additionalQuery }) => {
            if (typeof entity?.entity !== "string") {
                console.error("Sweft Error 1978: Invalid entity passed to data.entityAllSavedQueries of VanguardService", entity);
                throw new Error("Sweft Error 1978: Invalid entity passed to data.entityAllSavedQueries of VanguardService");
            }
            if (dataMocking) {
                if (additionalQuery) {
                    return [
                        {
                            "id": "",
                            "query": "jsonlogicObject",
                            "counts": {
                                "matches": 5,
                            }
                        }
                    ];
                }
                return [
                    {
                        "id": "queryId",
                        "query": "jsonlogicObject",
                        "counts": {
                            "matches": 10
                        }
                    }
                ];
            }
            const response = await request({
                method: "GET",
                url: `${api}/data/${entity.entity}/saved-queries`,
            });
            return response?.data || [];
        },
        entitySavedQuery: async ({ entity, queryId }) => {
            if (typeof entity?.entity !== "string" || typeof queryId !== "string" || !queryId) {
                console.error("Sweft Error 1923: Invalid entity passed to data.entitySavedQuery of VanguardService", entity);
                throw new Error("Sweft Error 1923: Invalid entity passed to data.entitySavedQuery of VanguardService");
            }
            if (dataMocking) {
                return mockSavedQueryResults;
            }
            const response = await request({
                method: "GET",
                url: `${api}/data/${entity.entity}/saved-queries/${queryId}`,
            });
            return response?.data || [];
        }
    },
};

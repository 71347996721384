// @ts-check
import React, { createContext, useContext, useMemo } from "react";
import { useWorkspaces } from "@app/workspace/contexts/useWorkspaces";
import { getAlertsForRole, hydrateAlertObj } from "@app/contexts/useAlerts/utils";
import { useFeature } from 'flagged';
import { SavedQueryAlertsContextProvider } from "@app/contexts/useAlerts/savedQueryAlertsContextProvider";
import { useCurrentUserRole } from "@app/auth/useCurrentUserRole";
import { useConfigDataActor } from "@app/data/config/hooks/useConfigDataActor";

/**
 * @type {React.Context<AlertsContextValue>}
 */
export const AlertsContext = createContext();

/**
 * Custom consumer hook for the Alerts context
 * @returns {AlertsContextValue}
 */
export const useAlerts = () => {
    const context = useContext(AlertsContext);
    if (context === undefined) {
        throw new Error('useAlerts must be used within an AlertsContext.Provider');
    }
    return context;
};

export const useHydrateAlert = ( ) => {
    const { findWorkspaceObjBasedOnId } = useWorkspaces();
    const hydrateAlert = ({ alert }) => {
        const workspaceObj = findWorkspaceObjBasedOnId(alert.workspace);
        return {
            ...hydrateAlertObj({ alert, workspaceObj }),
        };
    };

    return {
        hydrateAlert
    };
};

/**
 * Custom provider component for the Alerts context.
 * Used for globally loading alerts into state and processes them for the current user role.
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const AlertsWrapper = ({ children }) => {
    const { hydrateAlert } = useHydrateAlert();
    const { currentUserRole } = useCurrentUserRole();
    const { dataActorData: alertList } = useConfigDataActor({ config: "alerts" });
    const alerts = useMemo(() => {
        const userAlertList = getAlertsForRole(alertList, currentUserRole);
        const hydratedAlertList = userAlertList.map((alertObj) => {
            return hydrateAlert({ alert: alertObj });
        });

        return Array.from(new Set(hydratedAlertList.map(( { alertId } ) => alertId))).map((alertId) =>
            hydratedAlertList.find((alert) => alert.alertId === alertId));
    }, [alertList, currentUserRole, hydrateAlert]);
    /**
     * @type {AlertsContextValue}
     */
    const contextValue = useMemo(() => ({
        alerts,
    }), [alerts]);

    return (
        <AlertsContext.Provider value={contextValue}>{children}</AlertsContext.Provider>
    );
};

export const SavedQueryAlertsWrapper = ({ children }) => {
    const shouldUseSavedQueryAlertsContext = useFeature('readGraphData') || useFeature('savedQueryAlerts');
    if (shouldUseSavedQueryAlertsContext) {
        return <SavedQueryAlertsContextProvider>{children}</SavedQueryAlertsContextProvider>;
    }
    return children;
};

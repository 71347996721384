import React from "react";
import { EntitlementsWrapper } from "@app/contexts/useEntitlements";
import { MessagesWrapper } from "@app/contexts/useMessages";
import { LayoutContextWrapper } from "@app/layout/contexts/useLayout";
import { WorkspacesWrapper } from "@app/workspace/contexts/useWorkspaces";
import { AlertsWrapper } from "@app/contexts/useAlerts";
import { ConfigDataWrapper } from "@app/data/config/context";
import { CreativePlanWrapper } from "@app/grid/contexts/creativePlanContext";
import { ReceiveSamplesWrapper } from "@app/grid/contexts/receiveSamplesContext";
import { flagNames, isFlagOn } from "@app/common/featureFlags";
import { DataWrapperWithAlerts } from "@app/data/DataWrapperWithAlerts";
import { SavedQueryAlertsWrapper } from "@app/contexts/useAlerts/context";

const dataContexts = [
    ConfigDataWrapper,
    EntitlementsWrapper,
    MessagesWrapper,
    WorkspacesWrapper,
    CreativePlanWrapper,
    LayoutContextWrapper,
    ...( !isFlagOn(flagNames.readGraphData || flagNames.savedQueryAlerts) ? [AlertsWrapper] : []),
    ReceiveSamplesWrapper,
    DataWrapperWithAlerts,
    SavedQueryAlertsWrapper
];

export const DataContextsWrapper = dataContexts.reduce((DataContextsWrapperAcc, NextDataContextWrapper) => function ({ children }) {
    return <DataContextsWrapperAcc>
        <NextDataContextWrapper>
            {children}
        </NextDataContextWrapper>
    </DataContextsWrapperAcc>;
},);
